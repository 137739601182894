.container {
    padding: 30px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .header {
    font-size: 2rem;
    margin-bottom: 40px;
    color: #333;
  }
  
  .carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .button {
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #5a8dee;
  }
  
  .therapy-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    overflow: hidden;
    width: 100%;
}
  .card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .icon {
    font-size: 2.5rem;
    color: #5a8dee;
    margin-bottom: 15px;
  }
  
  .title {
    font-size: 1.2rem;
    color: #444;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 0.9rem;
    color: #666;
  }
  
  /* Responsive styles */
  @media (max-width: 1024px) {
    .header {
      font-size: 1.8rem;
    }
  
    .icon {
      font-size: 2rem;
    }
  
    .title {
      font-size: 1rem;
    }
    .button {
        font-size: 1.5rem;
    }
  
    .description {
      font-size: 0.85rem;
    }
  }
  
  @media (max-width: 768px) {
    .header {
      font-size: 1.6rem;
    }
  
    .icon {
      font-size: 1.8rem;
    }
  
    .title {
      font-size: 0.9rem;
    }
    .button {
        font-size: 1rem;
    }
  
    .description {
      font-size: 0.8rem;
    }
  }
  