/* Container styling */

 
.gal-header {
    font-size: 2rem;
    color: #333;
    font-weight: bold;
    text-align: center;
  }
.unique-gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

/* Each gallery item */
.unique-gallery-item {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.unique-gallery-item:hover {
  transform: rotate(5deg) scale(1.1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Image styling */
.unique-gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease;
}

.unique-gallery-item:hover img {
  transform: scale(1.2);
}
.csr-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.csr{
  font-size:1.5rem;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.csr-image{
  display: flex;
  justify-content: center;
  align-items: center;
  height:350px;
  width: 350px;
 
  
}
.csr-image img{
  width: 100%;
  height: 100%;
   border-radius: 20px;
  object-fit: cover;
}
