/* Footer Container */
.footer-container {
    background-color: #ffffff;
    color: #919b04;
    padding: 40px 20px;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  /* Footer Section (Company, Social, Address) */
  .footer-section {
    flex: 1;
    min-width: 200px;
  }
  
  .footer-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #bbc102;
    margin-bottom: 20px;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
  }
  
  .footer-link {
    display: block;
    text-decoration: none;
    color: #1f6202;
    margin: 10px 0;
    font-size: 1.2rem;
    transition: color 0.3s;
  }
  
  .footer-link:hover {
    color: #27ae60;
  }
  
  .footer-social-icons {
    display: flex;
    justify-content: space-around;
    gap: 10px;
  }
  
  .footer-icon {
    font-size: 1.5rem;
    color: #015504;
    transition: color 0.3s;
  }
  
  .footer-icon:hover {
    color: #606a02;
  }
  
  .footer-address {
    font-size: 1rem;
    color: #046916;
    margin-top: 10px;
  }
  .footer-address li {
  padding: 5px;
  }
  
  /* Footer Bottom Section */
  .footer-bottom {
    background-color: #034a0f;
    padding: 10px 0;
  }
  
  .footer-bottom-text {
    font-size: 1rem;
    color: #bdc3c7;
    margin: 0;
  }
  .logo-container2 {
    display: flex;
    align-items: center;
  }
  
  .logo2 {
    width: 160px;
    height: auto;
  
   
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-section {
      margin-bottom: 20px;
    }
  }
  