.partners-container {
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .partners-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .sliderpart{
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slider-track {
    display: flex;
    gap: 20px;
    transition: transform 0.2s linear;
  }
  
  .slider-item {
    flex: 0 0 auto;
    width: 120px; /* Adjust size as needed */
    height: 80px; /* Adjust size as needed */
  }
  
  .slider-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  