/* General Styles */
.services-container {
    padding: 40px;
    background: linear-gradient(360deg, rgb(255 255 255), rgb(225 238 229));
    min-height: 100vh;
    text-align: center;
  }
  
  .services-header {
    font-size: 2rem;
    color: #333;
    font-weight: bold;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .services-card {
    
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(7, 133, 63, 0.33);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  
  .services-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(9, 154, 67, 0.54);
  }
  
  .services-image-wrapper {
    height: 190px;
    overflow: hidden;
  }
  
  .services-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .services-content {
    padding: 20px;
  }
  
  .services-icon {
    font-size: 2.5rem;
    color: #0d6704;
    border-radius: 20px;
  }
  
  .services-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .services-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  .view-course-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 5px;
  }
  
  .view-course-button:hover {
    background-color: #0056b3;
  }
 
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
  }
.dropdown-container {
  position: absolute; 
  top: 0%;
  left: 0;
  height: 350px; 
  padding: 15px;
  background-color: #fdfbd5;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  display: none; 
  
  opacity: 0; 
  pointer-events: none; 
  transition: opacity 0.3s ease; 
  overflow: auto;
}

.dropdown-container.show {
  display: block; 
  opacity: 1; 
  pointer-events: auto;
}

.courses-list {
  list-style-type: none;
  padding-bottom: 42px;
  margin: 0;
}

.course-item {
  margin-bottom: 20px;
}

.course-title {
  font-size: 20px;
  font-weight: 500;
  color: #0077b6;
}

.subcourse-list {
  list-style-type: none;
  padding-left: 20px;
}

.subcourse-item {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
  background-color: white;
}

  @media (max-width: 768px) {
    .services-header {
      font-size: 1.8rem;
    }
  
    .services-card {
      box-shadow: 0 3px 8px rgba(7, 133, 63, 0.2);
    }
    .services-content {
        padding: 10px;
      }
      
  
    .services-image-wrapper {
      height: 150px;
    }
  
    .services-icon {
      font-size: 2rem;
    }
  
    .popup-content {
      width: 95%;
      max-height: 80%;
    }
  
    .services-title {
      font-size: 1.2rem;
    }
  
    .services-description {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .services-header {
      font-size: 1.5rem;
    }
  
    .services-image-wrapper {
      height: 120px;
    }
  
    .services-title {
      font-size: 1.2rem;
    }
    .services-content {
        padding: 5px;
      }
    .services-description {
      font-size: 0.85rem;
    }
  }
  