/* General Slider Styling */
.slider {
  position: relative;
  width: 100%;
  top: 0;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: fade 0.5s ease-in;
}

.slide img {
  width: 100%;
  height: 90vh;
  object-fit: cover;
}

.content {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  text-align: center;
  padding: 20px 30px;
  border-radius: 10px;
  width: 50%;
}

.content .img {
  height: 500px;
  width: 500px;
}

.content button {
  background: linear-gradient(180deg, #ffe100, #cec81e);
  padding: 12px 25px;
  border: none;
  width: 150px;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 10px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.content button:hover {
  background: linear-gradient(180deg, #cec81e, #ffe100);
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.content button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.content h2 {
  margin: 0;
  font-size: 2.5rem;
}

.content p {
  margin: 10px 0 0;
  font-size: 1.2rem;
}

/* Arrow Styling */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  transition: background 0.3s ease;
}

.arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}

.arrow.left {
  left: 10px;
  transition: transform 0.3s ease;
}

.arrow.left:hover {
  transform: rotate(360deg);
  color: #f1d901;
}

.arrow.right {
  right: 10px;
  transition: transform 0.3s ease;
}

.arrow.right:hover {
  transform: rotate(360deg);
  color: #f1d901;
}

/* Animation */
@keyframes fade {
  0% {
      opacity: 0.5;
      transform: scale(0.95);
  }
  100% {
      opacity: 1;
      transform: scale(1);
  }
}

/* Responsive Styling */

/* Tablet View */
@media (max-width: 1024px) {
  .content {
      width: 70%;
  }

  .content h2 {
      font-size: 2rem;
  }

  .content p {
      font-size: 1rem;
  }

  .content button {
      width: 150px;
      font-size: 18px;
  }

  .arrow {
      font-size: 1.8rem;
      padding: 8px;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .content {
      width: 90%;
      padding: 15px 20px;
  }

  .content h2 {
      font-size: 1.5rem;
  }

  .content p {
      font-size: 0.9rem;
  }

  .content button {
      width: 150px;
      font-size: 15px;
  }

  .arrow {
      font-size: 1.2rem;
      padding: 6px;
  }
  .content {
    position: absolute;
    top: 20%;
    left: 50%;
  }
}
