/* General Container Styles */
.contact-us-container {
    padding: 60px 20px;
    background-color: #f4f4f9;
    font-family: Arial, sans-serif;
  }
  
  .contact-us-title {
    text-align: center;
    font-size: 2.5rem;
    color: #2c3e50;
    font-weight: bold;
  }
  
  .contact-us-subtitle {
    text-align: center;
    font-size: 1.1rem;
    color: #7f8c8d;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  
  /* Contact Info Box */
  .contact-info-box {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 40px;
   
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 40px;
  }
  
  .contact-info-item {
    display: flex;
    align-items: center;
  }
  
  .contact-icon {
    font-size: 2.5rem;
    color: #27ae60;
    margin-right: 20px;
  }
  
  .contact-details h3 {
    font-size: 1.4rem;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .contact-details p {
    font-size: 1rem;
    color: #555;
  }
  .contact-details li {
    font-size: 1rem;
    padding:5px;
    color: #555;
  }
  .contact-link {
    color: #27ae60;
    font-weight: bold;
    text-decoration: none;
  }
  
  .contact-link:hover {
    text-decoration: underline;
  }
  
  /* Embedding Google Map */
  .address-map-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .google-map iframe {
    border-radius: 8px;
    width: 100%;
    height: 450px;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .contact-info-box {
      grid-template-columns: 1fr;
      padding: 20px;
    }
  
    .contact-us-title {
      font-size: 2rem;
    }
   
    
    .contact-us-subtitle {
      font-size: 1rem;
    }
    .contact-details li {
      font-size: 1rem;
      color: #555;
      padding:5px;
    }
  }
  
  @media (max-width: 450px) {
    .contact-info-box {
      grid-template-columns: 1fr;
      padding: 20px;
    }
  
    .contact-us-title {
      font-size: 1.5rem;
    }
    .contact-details h3 {
      font-size: 1.1rem;
     
    }
    .contact-icon {
      font-size: 2rem;
      color: #27ae60;
      margin-right: 20px;
    }
    
    .contact-details p {
      font-size: 0.7rem;
    
    }
    .contact-details  ol {
      padding:0px;
    }
    .contact-details  li {
      font-size: 0.7rem;
      color: #555;
      padding:5px;
    }
    .contact-us-subtitle {
      font-size: 0.8rem;
    }
  }
  