.contact-icons-container {
    position: fixed;
    top: 500px;
    left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000;
  }
  
  .contact-icon2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
    font-size: 30px;
    animation: pulse 2s infinite;
    text-decoration: none;
  }
  
  .whatsapp-icon {
    color: #25d366;
    animation: bounce 2s infinite;
  }
  
  .call-icon {
    color: #007bff;
    animation: pulse 2s infinite;
  }
  
  /* Pulse Animation */
  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
    50% {
      transform: scale(1.1);
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
  }
  
  /* Bounce Animation */
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  